<template>
  <div>
    <textarea :id="id"></textarea>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'editor'
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  mounted() {
    // Carrega o script do TinyMCE da URL fornecida
    const script = document.createElement('script');
    // script.src = 'https://editor.portalgov.info/js/tinymce/tinymce.min.js';
    script.src = 'https://editor-tiny-mce.pages.dev/js/tinymce/tinymce.min.js';
    script.onload = () => {
      tinymce.init({
        selector: `#${this.id}`,
        language: 'pt_BR',
        license_key: 'gpl',
        plugins: [
          'advlist', 'lists', 'charmap', 'directionality',
          'table', 'wordcount', 'quickbars'
        ],
        toolbar: " undo redo |bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | forecolor backcolor removeformat | charmap | ltr rtl",      
        menubar: "edit insert format table",
        branding: false,
        promotion: false,
        height: 500,
        image_advtab: false,
        quickbars_insert_toolbar: false,
        setup: (editor) => {
          editor.on('input change undo redo', () => {
            this.$emit('update:modelValue', editor.getContent());
          });
        },
        init_instance_callback: (editor) => {
          editor.setContent(this.modelValue);
        }
      });
    };
    document.head.appendChild(script);
  },
  beforeUnmount() {
    tinymce.remove(`#${this.id}`);
  }
};
</script>